<template>
  <!-- add-dealer-area -->
  <div class="add-dealer">
    <div class="add-dealer-form">
      <form @submit.prevent="updateDealer">
        <div class="box-container mb-2">
          <div class="detais-title">
            <h3>Dealership Information</h3>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Dealership Name</label>
              <input
                type="text"
                v-model="dealer.dl_name"
                class="form-control"
                placeholder="Dealership Name"
              />
              <div
                class="validate-error-message"
                v-if="$vd.dealer.dl_name.$hasError === true"
              >
                {{ $vd.dealer.$errors.dl_name[0] }}
              </div>
            </div>
            <div class="form-group col-md-6">
              <label>Dealership Main Phone Number</label>
              <input
                type="text"
                v-model="dealer.dl_phone"
                class="form-control"
                placeholder="Dealership Main Phone Number"
              />
              <div
                class="validate-error-message"
                v-if="$vd.dealer.dl_phone.$hasError === true"
              >
                {{ $vd.dealer.$errors.dl_phone[0] }}
              </div>
            </div>
            <div class="form-group col-md-6">
              <label>Autolink ID</label>
              <input
                type="number"
                v-model="dealer.dl_autolink_id"
                class="form-control"
                placeholder="Autolink ID"
              />
              <div
                class="validate-error-message"
                v-if="$vd.dealer.dl_autolink_id.$hasError === true"
              >
                {{ $vd.dealer.$errors.dl_autolink_id[0] }}
              </div>
            </div>
            <div class="form-group col-md-6">
              <label>Dealership URL</label>
              <input
                type="text"
                v-model="dealer.dl_url"
                class="form-control"
                placeholder="Dealership Url"
              />
            </div>
            <div class="form-group col-md-12">
              <label>Address</label>
              <input
                type="text"
                v-model="dealer.dl_address"
                class="form-control"
                placeholder="Address"
              />
              <div
                class="validate-error-message"
                v-if="$vd.dealer.dl_address.$hasError === true"
              >
                {{ $vd.dealer.$errors.dl_address[0] }}
              </div>
            </div>
            <div class="form-group col-md-6">
              <label>City</label>
              <input
                type="text"
                v-model="dealer.dl_city"
                class="form-control"
                placeholder="City"
              />
              <div
                class="validate-error-message"
                v-if="$vd.dealer.dl_city.$hasError === true"
              >
                {{ $vd.dealer.$errors.dl_city[0] }}
              </div>
            </div>
            <div class="form-group col-md-6">
              <label>State</label>
              <select class="form-control" v-model="dealer.dl_state">
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AZ">AZ</option>
                <option value="AR">AR</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="IA">IA</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="ME">ME</option>
                <option value="MD">MD</option>
                <option value="MA">MA</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MS">MS</option>
                <option value="MO">MO</option>
                <option value="MT">MT</option>
                <option value="NB">NB</option>
                <option value="NE">NE</option>
                <option value="NS">NS</option>
                <option value="NV">NV</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NY">NY</option>
                <option value="NC">NC</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="ON">ON</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="PE">PE</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WV">WV</option>
                <option value="WI">WI</option>
                <option value="WY">WY</option>
              </select>
              <div
                class="validate-error-message"
                v-if="$vd.dealer.dl_state.$hasError === true"
              >
                {{ $vd.dealer.$errors.dl_state[0] }}
              </div>
            </div>
            <div class="form-group col-md-6">
              <label>Zip Code</label>
              <input
                type="text"
                v-model="dealer.dl_zip"
                class="form-control"
                placeholder="Zip Code"
              />
              <div
                class="validate-error-message"
                v-if="$vd.dealer.dl_zip.$hasError === true"
              >
                {{ $vd.dealer.$errors.dl_zip[0] }}
              </div>
            </div>
          </div>
        </div>
        <div class="box-container mb-2 mt-3">
          <div class="detais-title">
            <h3>Billing Information</h3>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Billing Address</label>
              <input
                type="text"
                v-model="dealer.dl_bill_adsress"
                class="form-control"
                placeholder="Billing Address"
              />
            </div>

            <div class="form-group col-md-6">
              <label>Billing City</label>
              <input
                type="text"
                v-model="dealer.dl_bill_city"
                class="form-control"
                placeholder="Billing City"
              />
            </div>

            <div class="form-group col-md-6">
              <label>Billing State</label>
              <select class="form-control" v-model="dealer.dl_bill_state">
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AZ">AZ</option>
                <option value="AR">AR</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="IA">IA</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="ME">ME</option>
                <option value="MD">MD</option>
                <option value="MA">MA</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MS">MS</option>
                <option value="MO">MO</option>
                <option value="MT">MT</option>
                <option value="NB">NB</option>
                <option value="NE">NE</option>
                <option value="NS">NS</option>
                <option value="NV">NV</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>
                <option value="NY">NY</option>
                <option value="NC">NC</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="ON">ON</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="PE">PE</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WV">WV</option>
                <option value="WI">WI</option>
                <option value="WY">WY</option>
              </select>

              <!-- <input
                  type="text"
                  v-model="dealer.dl_bill_state"
                  class="form-control"
                  placeholder="Billing State"
                /> -->
            </div>

            <div class="form-group col-md-6">
              <label>Billing Zip Code</label>
              <input
                type="text"
                v-model="dealer.dl_bill_zipcode"
                class="form-control"
                placeholder="Billing Zip Code"
              />
            </div>
          </div>
        </div>
        <div class="box-container mb-2 mt-3">
          <div class="detais-title">
            <h3>Admin Information</h3>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Admin Name</label>
              <input
                type="text"
                v-model="dealer.dl_admin_name"
                class="form-control"
                placeholder="Admin Name"
              />
            </div>

            <div class="form-group col-md-6">
              <label>Admin Email</label>
              <input
                type="text"
                v-model="dealer.dl_admin_email"
                class="form-control"
                placeholder="Admin Email"
              />
            </div>

            <div class="form-group col-md-6">
              <label>Admin Phone</label>
              <input
                type="text"
                v-model="dealer.dl_admin_phone"
                class="form-control"
                placeholder="Admin Phone"
              />
            </div>
          </div>
        </div>
        <div class="box-container mb-2 mt-3">
          <div class="detais-title">
            <h3>IT Contact</h3>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Dealership IT Contact</label>
              <input
                type="text"
                v-model="dealer.dl_it_contact"
                class="form-control"
                placeholder="Dealership IT Contact"
              />
            </div>

            <div class="form-group col-md-6">
              <label>Dealership IT Contact Email</label>
              <input
                type="text"
                v-model="dealer.dl_email"
                class="form-control"
                placeholder="Dealership IT Contact Email"
              />
              <div
                class="validate-error-message"
                v-if="$vd.dealer.dl_email.$hasError === true"
              >
                {{ $vd.dealer.$errors.dl_email[0] }}
              </div>
            </div>

            <div class="form-group col-md-6">
              <label>Dealership IT Contact Phone</label>
              <input
                type="text"
                v-model="dealer.dl_it_contact_phone"
                class="form-control"
                placeholder="Dealership It Contact Phone"
              />
            </div>
          </div>
        </div>
        <!--  <div class="box-container mb-2 mt-3">
                <div class="detais-title">
                    <h3>Import Data</h3>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label>Past CRM</label>
                        <input type="text" v-model="dealer.dl_pastcrm" class="form-control" placeholder="Past CRM">
                    </div>
                </div>
              </div>  -->
        <div class="box-container mb-2 mt-3">
          <div class="detais-title">
            <h3>Backend</h3>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Activation Date</label>
              <input
                type="text"
                v-model="dealer.dl_activation_date"
                class="form-control"
                placeholder="4/12/20"
                disabled
              />
            </div>
            <div class="form-group col-md-6">
              <label>Contract Recieved Date</label>
              <input
                type="date"
                v-model="dealer.dl_recieved_date"
                class="form-control"
                placeholder="Contract Recieved Date"
              />
            </div>
            <div class="form-group col-md-6">
              <label>Term Date</label>
              <input
                type="date"
                v-model="dealer.dl_term_date"
                class="form-control"
                placeholder="Term Date"
              />
            </div>
            <div class="form-group col-md-6">
              <multi-select
                :searchable="true"
                id="a_users"
                textColumn="text"
                placeholder="Select Sources"
                v-model="dealer.source"
                :alreadySelected="selected_dealers"
                :options="allSources"
                label="Assign Lead Sources"
              ></multi-select>
            </div>
            <!-- <div class="form-group col-md-6">
              <label>Twillio account</label>
              <input
                type="text"
                v-model="dealer.dl_twilio_phone"
                class="form-control"
                placeholder="+14582145"
              />
            </div> -->

            <div class="form-group col-md-4">
              <a
                style="margin-top: 25px"
                class="call-modal"
                href="#"
                data-toggle="modal"
                data-target="#exampleModal50"
                >Create a New Number</a
              >
            </div>

            <div class="form-group form-check col-md-12">
              <div class="custom-control custom-checkbox">
                <input
                  value="1"
                  type="checkbox"
                  class="custom-control-input"
                  v-model="dealer.dl_terms"
                  id="customCheckBox1"
                />
                <label class="custom-control-label" for="customCheckBox1"
                  >Contract terms to this subscription</label
                >
                <p>
                  The officia consequat duis enim velit mollit. Exercitation
                  veniam consequat sunt.
                  <a href="#"
                    >learn more <img src="assets/img/link.svg" alt=""
                  /></a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="box-container mb-2 mt-3">
          <div class="detais-title">
            <h3>SMTP Details</h3>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Driver</label>
              <input
                type="text"
                v-model="dealer.driver"
                class="form-control"
                placeholder="Driver"
              />
            </div>
            <div class="form-group col-md-6">
              <label>Outgoing Mail (SMTP) Server</label>
              <input
                type="text"
                v-model="dealer.out_host"
                class="form-control"
                placeholder="Outgoing Mail (SMTP) Server"
              />
            </div>
            <div class="form-group col-md-6">
              <label>Outgoing Port</label>
              <input
                type="text"
                v-model="dealer.out_port"
                class="form-control"
                placeholder="Outgoing Port"
              />
            </div>
            <div class="form-group col-md-6">
              <label>Incoming Mail (IMAP) Server</label>
              <input
                type="text"
                v-model="dealer.in_host"
                class="form-control"
                placeholder="Incoming Mail (IMAP) Server"
              />
            </div>
            <div class="form-group col-md-6">
              <label>Incoming Port</label>
              <input
                type="text"
                v-model="dealer.in_port"
                class="form-control"
                placeholder="Incoming Port"
              />
            </div>
            <div class="form-group col-md-6">
              <label>Username</label>
              <input
                type="text"
                v-model="dealer.username"
                class="form-control"
                placeholder="Username"
              />
            </div>
            <div class="form-group col-md-6">
              <label>Password</label>
              <input
                type="password"
                v-model="dealer.password"
                class="form-control"
                placeholder="Password"
              />
            </div>
            <div class="form-group col-md-6">
              <label>Encryption</label>
              <select class="form-control" v-model="dealer.encryption">
                <option value="">Choose Encryption</option>
                <option value="tls">tls</option>
                <option value="ssl">ssl</option>
              </select>
            </div>
          </div>
        </div>
        <div class="box-container mb-2 mt-3">
          <!-- Features -->
          <div class="features">
            <div class="detais-title">
              <h3>Features</h3>
            </div>
            <ul>
              <li>
                <a href="#">
                  <label class="switch">
                    <input type="checkbox" value="1" v-model="permissions" />
                    <span class="slider round"></span>
                  </label>
                  Call Tracking
                </a>
              </li>
              <li>
                <a href="#">
                  <label class="switch">
                    <input type="checkbox" value="2" v-model="permissions" />
                    <span class="slider round"></span>
                  </label>
                  Marketing
                </a>
              </li>
              <li>
                <a href="#">
                  <label class="switch">
                    <input type="checkbox" value="3" v-model="permissions" />
                    <span class="slider round"></span>
                  </label>
                  Click To Call
                </a>
              </li>
              <li>
                <a href="#">
                  <label class="switch">
                    <input type="checkbox" value="4" v-model="permissions" />
                    <span class="slider round"></span>
                  </label>
                  Desking
                </a>
              </li>
            </ul>
          </div>
          <!-- Features -->
        </div>
        <div class="modal-footer">
          <a @click="$router.push('/admin/dealers')" class="add-btn"> Cancel</a>
          <button :disabled="btnLoader" type="submit" class="rem-btn">
            <span v-if="btnLoader"
              ><span class="spinner-grow spinner-grow-sm"></span>
              Loading...</span
            >
            <span v-if="!btnLoader"> update</span>
          </button>
        </div>
      </form>
    </div>
    <div
      class="modal fade"
      id="exampleModal50"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Create a New Number
            </h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div class="modal-body">
            <div class="finece-option">
              <form method="">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="inputEmail4">Phone number</label>
                    <input
                      type="text"
                      v-on:input="searchNumbers"
                      v-model="searchnumber"
                      class="form-control"
                      id="inputEmail42"
                      placeholder="Enter area code"
                      autocomplete="off"
                    />
                    <img
                      v-if="numbers.length == 0 && searchnumber && display"
                      style="
                        position: absolute;
                        left: 93%;
                        width: 28px;
                        top: 28px;
                      "
                      src="../../../assets/img/Spinner.gif"
                    />
                    <ul
                      v-if="numbers.length > 0"
                      style="
                        background: #fff;
                        height: 101px;
                        overflow-y: scroll;
                      "
                    >
                      <li
                        v-for="(number, index) in numbers"
                        :key="index"
                        style="padding: 5px"
                      >
                        <div class="box">
                          <div class="">
                            <span @click="selectnumber(number)">{{
                              number
                            }}</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <a :disabled="buynoLoader" class="rem-btn" @click="buyNumber">
              <span v-if="buynoLoader"
                ><span class="spinner-grow spinner-grow-sm"></span>
                Loading...</span
              >
              <span v-if="!buynoLoader"> Submit</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- add-dealer-area -->

  <!-- <div class="email-campaign">
        <div class="email-deatils">
            <form @submit.prevent="addNewDealer">
                <div class="row">
                    <div class="form-group col-md-6">
                        <label for="dealer name">Dealer Name *</label>
                        <input type="text" v-model="dealer.dl_name" placeholder="Dealer Name" class="form-control" />
                    </div>
                    <div class="form-group sub-emoji col-md-6">
                        <label for="Subject">Main Phone (Sales) </label>
                        <input type="tel" v-model="dealer.dl_phone" placeholder="Main Phone" class="form-control" />
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-6">
                        <label>Email</label>
                        <input type="email" v-model="dealer.dl_email" placeholder="Email" class="form-control" />
                    </div>
                    <div class="form-group col-md-6">
                        <label>Address</label>
                        <input type="text" v-model="dealer.dl_address" placeholder="Address" class="form-control" />
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-6">
                        <label>Street Address</label>
                        <input type="text" v-model="dealer.dl_street_address" placeholder="Street Address" class="form-control" />
                    </div>
                    <div class="form-group col-md-6">
                        <label>Unit/App #</label>
                        <input type="number" v-model="dealer.dl_unit" placeholder="Unit/App" class="form-control" />
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-6">
                        <label>City</label>
                        <input type="text" v-model="dealer.dl_city" placeholder="City" class="form-control" />
                    </div>
                    <div class="form-group col-md-6">
                        <label>Zip Code</label>
                        <input type="text" v-model="dealer.dl_zipcode" placeholder="Zip Code" class="form-control" />
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-6">
                        <label>Crm Email</label>
                        <input type="text" v-model="dealer.dl_crm_email" placeholder="Crm Email" class="form-control" />
                    </div>
                    <div class="form-group col-md-6">
                        <label>Twillio Number</label>
                        <input type="text" v-model="dealer.dl_twilio_phone" placeholder="Twillio Number" class="form-control" />
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-md-6">
                        <label>Activation Date</label><br />
                        <date-range-picker
                            :min-date="yesterdayDate"
                            v-model="dealer.addApt.ufDate"
                            :locale-data="{ format: 'mmmm dd, yyyy' }"
                            :single-date-picker="true"
                            :show-dropdowns="true"
                            :auto-apply="true"
                            :ranges="false"
                        ></date-range-picker>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-7">
                        <div style="float: left;">
                            <input type="checkbox" checked />
                        </div>

                        <div style="float: right;">
                            <p><b>Contract terms to this subscription</b></p>
                            <p>
                                The officia consequat duis enim mollit.Exercitation veniam consequat sunt.Learn more
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-md-12">
                    <h3>Features</h3>
                </div>

                <div class="row">
                    <div class="form-group col-md-3">
                        <label class="switch"><input type="checkbox" @change="check('calltracking')" checked="checked" /><span class="slider round"></span></label><br />
                        <br />
                    </div>
                </div>

                <div class="pull-right">
                    <button type="submit" class="rem-btn">
                        <span><img class="img-fluid" src="../../../assets/img/plus.png" alt="" /> Add</span>
                    </button>
                </div>
            </form>
        </div>
    </div> -->
</template>

<script>
import VueDaval from "vue-daval";
import axios from "axios";
import $ from "jquery";
//import DateRangePicker from "vue2-daterange-picker";
//import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import MultiSelect from "../../helpers/AdminMultiSelect";
export default {
  name: "Dealers",
  components: {
    MultiSelect,
  },
  mixins: [VueDaval],
  data() {
    return {
      searchnumber: "",
      buynoLoader: "",
      numbers: [],
      callsetting: {
        number: "",
      },
      display: true,
      allSources: [],
      btnLoader: false,
      permissions: [],
      dealer: {
        dl_name: "",
        dl_phone: "",
        dl_email: "",
        dl_autolink_id: "",
        dl_address: "",
        dl_zip: "",
        dl_city: "",
        dl_state: "",
        dl_activation_date: moment().format("MM/DD/YYYY"),
        source: [],
        alreadyAssigned: [],
        driver: "",
        in_host: "",
        out_host: "",
        in_port: "",
        out_port: "",
        username: "",
        password: "",
        encryption: "",
        permissions: [],
      },
      selected_dealers: [],
    };
  },
  vdRules: {
    dealer: {
      dl_name: { required: true },
      dl_phone: { required: true },
      dl_email: { required: true, type: "email" },
      dl_autolink_id: { required: true },
      dl_address: { required: true },
      dl_zip: { required: true },
      dl_city: { required: true },
      dl_state: { required: true },
    },
  },

  created() {
    this.getDealer();
    this.getSources();
    this.getLeadSources();
    let vs = this;
    this.$root.$on("action", function (action) {
      if (action == "checkalladminleasesources") {
        vs.dealer.source = vs.allSources;
      }
      if (action == "unselectalladminleasesources") {
        vs.dealer.source = [];
      }
    });
  },
  methods: {
    selectnumber(num) {
      this.callsetting.number = num;
      this.searchnumber = num;
      this.dealer.dl_twilio_phone = num;
      this.numbers = [];
      this.display = false;
    },
    searchNumbers() {
      this.loader = true;
      this.display = true;
      if (this.searchnumber) {
        axios
          .get("searchnumber/" + this.searchnumber, {
            params: {
              dealer_id: this.$route.params.id,
            },
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          })
          .then((res) => {
            this.numbers = res.data.numbers;
            this.loader = false;
          })
          .catch((err) => {
            console.log(err);
            this.loader = false;
          });
      } else {
        this.numbers = [];
        this.loader = false;
      }
    },
    buyNumber() {
      this.buynoLoader = true;
      axios
        .post("adminPurchaseNewNumber", this.callsetting, {
          params: {
            dealer_id: this.$route.params.id,
          },
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            this.buynoLoader = false;
            this.callsetting = {};
            $("#exampleModal50").modal("hide");
            this.$toast.open({
              position: "top-right",
              message: res.data.message,
              type: "success",
            });
          } else {
            this.buynoLoader = false;
            console.log("44");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSources() {
      axios
        .get("lead_sources", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            if (data.success.length > 0) {
              var dd = [];
              $.each(data.success, function (key, value) {
                dd.push({ id: value.ls_id, text: value.ls_name });
              });
              this.allSources = dd;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLeadSources() {
      axios
        .get("delaer/leadSources/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.success.length > 0) {
            var sd = [];
            $.each(res.data.success, function (key, value) {
              sd.push({ id: value.ls_id, text: value.ls_name });
            });
            this.selected_dealers = sd;
            this.dealer.alreadyAssigned = sd;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDealer() {
      axios
        .get("admin/dealer_detail/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.data.success) {
            this.dealer = res.data.dealer;
            var arr = res.data.dealer.dl_permissions.split(",");
            this.permissions = arr;
            console.log(this.permissions);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateDealer() {
      this.btnLoader = true;
      this.dealer.permissions = [];
      this.dealer.permissions = this.permissions;
      //let startDate = this.dealer.addApt.ufDate.startDate;
      //this.dealer.addApt.date = moment(new Date(startDate)).format("YYYY-MM-DD");
      this.$vd.dealer
        .$validate()
        .then(() => {
          axios
            .post("admin/update_dealer", this.dealer, {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            })
            .then((res) => {
              //console.log(res);
              if (res.data.success == false) {
                this.btnLoader = false;
                this.$toast.open({
                  position: "top-right",
                  message: res.data.message,
                  type: "info",
                });
                return false;
              }
              if (res.data.success) {
                this.$router.push("/admin/dealers");
              }
              this.btnLoader = false;
            })
            .catch((err) => {
              this.btnLoader = false;
              console.log(err);
            });
        })
        .catch(() => {
          this.btnLoader = false;
        });
    },
  },
};
</script>
<style scoped>
li:hover {
  background: #ddd;
  cursor: pointer;
}
.add-dealer {
  width: 100%;
  background: transparent;
  padding: 0;
  border-radius: 0;
}
.box-container {
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
}
@media screen and (min-width: 1440px) {
  .add-dealer {
    width: 70%;
  }
}
</style>
